import React from "react"

import Layout from "../components/Layout"
import PageTitleSmall from "../components/PageTitleSmall"
import SEO from "../components/seo"
import PageContent from "../components/PageContent"

const Impressum = () => (
  <Layout>
    <SEO title="Impressum" />
    <PageTitleSmall title="Impressum" />
    <PageContent alignment="center">
      <h2>Bosphorus OG</h2>
      <h3>Persönlich haftender Gesellschafter: Ferhat Ipek</h3>
      <h3>Persönlich haftender Gesellschafter: Canan Ipek</h3>
      <h3>Straße: Schlitters 48a</h3>
      <h3>Ort: 6262 Schlitters</h3>
      <h3>
        Telefon: <a href="tel:+43 5288 72299">+43 5288 72299</a>
      </h3>
      <h3>
        Mobil: <a href="tel:+43 676 6705016">+43 676 6705016</a>
      </h3>
      <h3>
        E-Mail:{" "}
        <a href="mailto:service@bosphorus-restaurant.at">
          service@bosphorus-restaurant.at
        </a>
      </h3>
      <br />
      <br />
      <h3>Firmenbuchnummer: /</h3>
      <h3>UID-Nummer: ATU 74722017</h3>
      <p>
        Übermittelte Daten werden den gesetzlichen Bestimmungen entsprechend
        streng vertraulich behandelt. Die Daten werden nicht an Dritte
        weitergegeben.
      </p>
    </PageContent>
  </Layout>
)

export default Impressum
