import React from "react"
import "./index.scss"

class PageContent extends React.Component {
  constructor(props) {
    super(props)
    let alignment = this.props.alignment
    this.state = {
      alignment: alignment === "center" ? "center" : "left",
    }
  }
  render() {
    return (
      <section
        className={
          this.state.alignment === "center" ? "content" : "content left"
        }
      >
        {this.props.children}
      </section>
    )
  }
}

export default PageContent
